import { createApiRef } from '@backstage/core-plugin-api';
import {
  JiraWorkflowTypes,
  KeyValueType,
} from '../../components/Modals/ConfigurationDialog';
import { cleanQueryString, groupFilterByName } from '../../utils';
import { Options } from '..';
import { JiraClient } from './Client';

export const jiraDoraApiRef = createApiRef<JiraDoraApi>({
  id: 'plugin.sanofi-dora-metrics.jira.service',
});
const JIRA_FIELDS = [
  'resolutiondate',
  'created',
  'priority',
  'severity',
  'issuetype',
  'project',
  'component',
  'environment',
];
export const MAX_RESULTS = 100;

export type JiraQueryResponse = {
  results: Array<JiraIssue>;
  jql: string;
};

export class JiraDoraApi {
  private readonly clientApi: JiraClient;
  constructor({ discoveryApi, configApi, fetchApi }: Options) {
    this.clientApi = new JiraClient({ discoveryApi, configApi, fetchApi });
  }

  public static getComputedResolvedBugQuery(
    projectKey: string,
    components: string[],
  ) {
    const componentQuery = components.length
      ? `AND component in (${components.join(',')})`
      : '';

    return `
      resolutiondate > -4w
      AND issuetype = 'Bug'
      AND project = ${projectKey}
      AND (priority in (Highest, High, Blocker))
      AND "Environment[Dropdown]" = Production
      ${componentQuery}`;
  }

  public static getComputedResolvedQuery(
    workflowType: JiraWorkflowTypes,
    projectKey: string,
    components: string[],
    completedStatusFilters: KeyValueType[],
  ): string {
    const groupedFilters = groupFilterByName(completedStatusFilters);

    const workflowQuery =
      workflowType === 'complex' && Object.keys(groupedFilters).length
        ? `AND (${Object.keys(groupedFilters)
            .map(key => `${key} in ("${groupedFilters[key].join('","')}")`)
            .join(' OR ')})`
        : '';

    const componentQuery = components.length
      ? `AND component in (${components.join(',')})`
      : '';

    const baseQuery = `
      resolutiondate > -4w 
      AND issuetype in (Story, Task) 
      AND project = ${projectKey}
      `;

    return `${baseQuery} ${workflowQuery} ${componentQuery}`;
  }

  static getComputedHighPriorityBugsIntroducedQuery(
    projectKey: string,
    components: string[],
  ) {
    const componentQuery = components.length
      ? `AND component in (${components.join(',')})`
      : '';

    const baseQuery = `
      created > -4w 
      AND issuetype = Bug
      AND project = ${projectKey}
      AND (priority in (Highest, High, Blocker))
      AND "Environment[Dropdown]" = Production`;

    return `${baseQuery} ${componentQuery}`;
  }

  async getResolvedProductionBugs({
    projectKey,
    workflowType,
    components,
    customJql,
  }: {
    projectKey: string;
    workflowType: JiraWorkflowTypes;
    components: string[];
    customJql?: KeyValueType;
  }): Promise<JiraQueryResponse> {
    let jql = null;
    if (workflowType === 'custom' || workflowType === 'custom-jql') {
      if (!customJql?.value) {
        throw new Error(
          'Custom JQL query is missing for high priority bugs introduced',
        );
      }
      jql = customJql.value;
    } else {
      jql = JiraDoraApi.getComputedResolvedBugQuery(projectKey, components);
    }

    const data = this.generateJiraQuery(cleanQueryString(jql));

    const results = (
      await this.clientApi.handlePaginatedSearch(data, { projectKey })
    ).issues;

    return {
      results: results,
      jql,
    };
  }

  async getResolvedFeatures({
    projectKey,
    workflowType,
    completedStatusFilters,
    components,
    customJql,
  }: {
    projectKey: string;
    workflowType: JiraWorkflowTypes;
    completedStatusFilters: KeyValueType[];
    components: string[];
    customJql?: KeyValueType;
  }): Promise<JiraQueryResponse> {
    let jql = null;
    if (workflowType === 'custom' || workflowType === 'custom-jql') {
      if (!customJql?.value) {
        throw new Error('Custom JQL query is missing for resolved features');
      }
      jql = customJql.value;
    } else {
      jql = JiraDoraApi.getComputedResolvedQuery(
        workflowType,
        projectKey,
        components,
        completedStatusFilters,
      );
    }

    const data = this.generateJiraQuery(cleanQueryString(jql));

    const results = (
      await this.clientApi.handlePaginatedSearch(data, { projectKey })
    ).issues;

    return {
      results,
      jql,
    };
  }

  async getHighPriorityBugsIntroduced({
    projectKey,
    workflowType,
    components,
    customJql,
  }: {
    projectKey: string;
    workflowType: JiraWorkflowTypes;
    components: string[];
    customJql?: KeyValueType;
  }): Promise<JiraQueryResponse> {
    let jql = null;
    if (workflowType === 'custom' || workflowType === 'custom-jql') {
      if (!customJql?.value) {
        throw new Error(
          'Custom JQL query is missing for high priority bugs introduced',
        );
      } else {
        jql = customJql.value;
      }
    } else {
      jql = JiraDoraApi.getComputedHighPriorityBugsIntroducedQuery(
        projectKey,
        components,
      );
    }

    const data = this.generateJiraQuery(cleanQueryString(jql));
    const results = (
      await this.clientApi.handlePaginatedSearch(data, { projectKey })
    ).issues;

    return { results, jql };
  }

  async getJiraIssuesForProject(
    projectKey: string,
    components: string[],
    jiraCompletedFilters: KeyValueType[],
    workflowType: JiraWorkflowTypes,
    customJqlQueries: KeyValueType[],
  ): Promise<JiraDoraPayload> {
    const [
      avgBugResolutionTimeQuery,
      avgChangeLeadTimeQuery,
      highPriorityBugsIntroducedQuery,
    ] = [
      'avgBugResolutionTime',
      'avgChangeLeadTime',
      'highPriorityBugsIntroduced',
    ].map(item =>
      customJqlQueries.find((query: KeyValueType) => query.key === item),
    );

    const baseProps = {
      projectKey,
      workflowType,
      components: components.filter(Boolean),
    };

    const [
      project,
      resolvedBugs,
      resolvedFeatures,
      highPriorityBugsIntroduced,
    ] = await Promise.all([
      this.clientApi.getProjectDetails(projectKey),
      this.getResolvedProductionBugs({
        ...baseProps,
        customJql: avgBugResolutionTimeQuery,
      }),
      this.getResolvedFeatures({
        ...baseProps,
        completedStatusFilters: jiraCompletedFilters,
        customJql: avgChangeLeadTimeQuery,
      }),
      this.getHighPriorityBugsIntroduced({
        ...baseProps,
        customJql: highPriorityBugsIntroducedQuery,
      }),
    ]);
    return {
      project,
      resolvedBugs,
      resolvedFeatures,
      highPriorityBugsIntroduced,
    };
  }

  private generateJiraQuery(jql: string) {
    return {
      maxResults: MAX_RESULTS,
      startAt: 0,
      jql,
      fields: JIRA_FIELDS,
      expand: ['changelog'],
    };
  }
}

export type Item = {
  field: string;
  fromString: string;
  toString: string;
};
export type History = {
  created: string;
  items: Array<Item>;
};

interface IssueFields {
  resolutiondate: string;
  created: string;
  timeToResolve?: string;
}

export type JiraIssue = {
  id: string;
  key: string;
  self: string;
  expand: string;
  changelog: {
    histories: Array<History>;
  };
  fields: IssueFields;
};

export type Project = {
  name: string;
  avatarUrls: Record<string, string>;

  issueTypes: Array<{
    name: string;
    iconUrl: string;
  }>;
  self: string;
  url: string;
  projectTypeKey: string;
};

export type PaginatedJiraIssues = {
  expand: string;
  startAt: number;
  maxResults: number;
  total: number;
  issues: Array<JiraIssue>;
};

export type JiraDoraPayload = {
  project: Project;
  resolvedBugs: JiraQueryResponse;
  highPriorityBugsIntroduced: JiraQueryResponse;
  resolvedFeatures: JiraQueryResponse;
};

export type JiraSearchPayload = {
  maxResults: number;
  startAt: number;
  jql: string;
  fields: Array<string>;
  expand: Array<string>;
};
