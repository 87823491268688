import { DatadogCostApi, datadogCostApiRef } from './datadog';
import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { WEEK_IN_SECONDS } from '../utils';

export type Options = {
  discoveryApi: DiscoveryApi;
  configApi: ConfigApi;
  identityApi: IdentityApi;
  fetchApi: FetchApi;
};

export type Filter = {
  key: string;
  value: string;
};

export type CostMetricQuery = {
  fromTimestamp: number;
  nowTimestamp: number;
  filters: Filter[];
  groupBy?: string[];
  rollUpTimeInSeconds?: number;
};

export const costApiRef = createApiRef<CostMetrics>({
  id: 'plugin.aws-cost-metrics.datadog.service',
});

class CostMetrics {
  private readonly datadog: DatadogCostApi;

  constructor(options: Options) {
    this.datadog = new DatadogCostApi(options);
  }

  async getCostMgmtMetrics({
    fromTimestamp,
    nowTimestamp,
    filters,
    groupBy = ['aws_product'],
    rollUpTimeInSeconds = WEEK_IN_SECONDS,
  }: CostMetricQuery): Promise<any> {
    const costMetricsByFilterPerDay = await this.datadog.getCostMetrics({
      fromTimestamp,
      nowTimestamp,
      filters,
      groupBy,
      rollUpTimeInSeconds,
    });
    return {
      data: costMetricsByFilterPerDay,
    };
  }
}
export { datadogCostApiRef, DatadogCostApi, CostMetrics };
