import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

const lightPalette = {
  ...palettes.light,
  primary: {
    main: '#23004C',
  },
  secondary: {
    main: '#565a6e',
  },
  navigation: {
    background: '#000000',
    indicator: '#7a00e6',
    color: '#d5d6db',
    selectedColor: '#ffffff',
  },
};

const darkPalette = {
  ...palettes.dark,
  primary: {
    main: '#cd9fff',
  },
  secondary: {
    main: '#d5d6db',
  },
  navigation: {
    background: '#000000',
    indicator: '#7a00e6',
    color: '#d5d6db',
    selectedColor: '#ffffff',
  },
};

export const backstageAcceleratorLightTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: lightPalette,
  }),
  fontFamily: 'Roboto, sans-serif',
  defaultPageTheme: 'default',
  pageTheme: {
    default: genPageTheme({
      colors: ['#23004C', '#7A00E6'],
      shape: shapes.wave,
    }),
  },
});

export const backstageAcceleratorDarkTheme = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: darkPalette,
  }),
  fontFamily: 'Roboto, sans-serif',
  defaultPageTheme: 'default',
  pageTheme: {
    default: genPageTheme({
      colors: ['#23004C', '#7A00E6'],
      shape: shapes.wave,
    }),
  },
});
