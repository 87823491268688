import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
} from '@backstage/core-plugin-api';
import { FilteredDoraProject, Options } from '..';
import { KeyValueType } from '../../components/Modals/ConfigurationDialog';
import { cleanQueryString } from '../../utils';

const DEFAULT_PROXY_PATH = '/datadog/api';
const DEFAULT_REST_API_VERSION = 'v2';

export const datadogDoraApiRef = createApiRef<DatadogDoraApi>({
  id: 'plugin.sanofi-dora-metrics.datadog.service',
});

export class DatadogDoraApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor({ discoveryApi, fetchApi }: Options) {
    this.discoveryApi = discoveryApi;
    this.fetchApi = fetchApi;
  }

  searchDeploymentsByTeamQuery = (
    pipelines: FilteredDoraProject[],
    filters: KeyValueType[],
    teamName: string,
    branchFilters: KeyValueType[],
    period: string = '30d',
  ): DatadogSearchBody => {
    const optionalFilters = filters
      // .filter(filter => filter.value)
      .map(filter => `@${filter.key}:${filter.value}`)
      .join(' AND ');
    let branchFilter = '';

    branchFilters.forEach((branch, index) => {
      let condition = 'OR';
      if (index === 0) {
        condition = 'AND';
      }

      if (branch.value.includes('*')) {
        branchFilter = branchFilter.concat(
          `${condition} @git.branch:${branch.value}`,
        );
      } else if (branch.value) {
        branchFilter = branchFilter.concat(
          `${condition} @git.branch:\"${branch.value}\"`,
        );
      }
    });

    const deploymentPipelineNamesQuery = pipelines
      .map(
        pipeline =>
          `${
            pipeline.deploymentJobName ? 'ci_level:job' : 'ci_level:pipeline'
          } AND 
              @git.repository.name:${pipeline.repositorySlug} AND 
              @ci.status:success AND @ci.provider.name:github AND 
              @ci.pipeline.name:\"${pipeline.deploymentPipeline}\"
              ${
                pipeline.deploymentJobName
                  ? `AND @ci.job.name:\"${pipeline.deploymentJobName}\"`
                  : ''
              }
              ${branchFilter}
              ${pipeline.hasMultipleOwners ? `AND @team:${teamName}` : ''}
              ${optionalFilters ? `AND ${optionalFilters}` : ''}
          `,
      )
      .join(' OR ');

    return {
      filter: {
        from: `now-${period}`,
        query: `(${cleanQueryString(deploymentPipelineNamesQuery)})`,
        to: 'now',
      },
      options: {
        timezone: 'GMT',
      },
      page: {
        limit: 100,
      },
      sort: 'timestamp',
    };
  };

  async getUrls() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return {
      apiUrl: `${proxyUrl}${DEFAULT_PROXY_PATH}/api/${DEFAULT_REST_API_VERSION}`,
      datadogUrl: `https://api.datadoghq.eu/api/${DEFAULT_REST_API_VERSION}`,
    };
  }

  async getTeamDeployments(
    period: string,
    filters: KeyValueType[],
    teamName: string,
    branch: KeyValueType[],
    pipelines: FilteredDoraProject[] = [],
  ): Promise<DatadogPipelineResult[]> {
    if (!pipelines.length) {
      return [];
    }
    const { apiUrl, datadogUrl } = await this.getUrls();
    const body = this.searchDeploymentsByTeamQuery(
      pipelines,
      filters,
      teamName,
      branch,
      period,
    );
    const request = await this.fetchApi.fetch(
      `${apiUrl}/ci/pipelines/events/search`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );

    if (!request.ok) {
      throw new Error(
        `failed to fetch data, status ${request.status}: ${request.statusText}`,
      );
    }

    const res = (await request.json()) as DatadogDoraPayload;

    let nextLink = res?.links?.next ?? null;

    while (nextLink) {
      nextLink = nextLink.replace(datadogUrl, apiUrl);
      const nextRequest = await this.fetchApi.fetch(nextLink, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (!nextRequest.ok) {
        throw new Error(
          `failed to fetch data, status ${nextRequest.status}: ${nextRequest.statusText}`,
        );
      }

      const nextResponse = (await nextRequest.json()) as DatadogDoraPayload;
      res.data = [...res.data, ...nextResponse.data];
      nextLink = nextResponse?.links?.next ?? null;
    }

    return res.data;
  }
}
interface Committer {
  date: string;
  name: string;
  email: string;
  date_timestamp: number;
}

interface Author {
  name: string;
  email: string;
}

interface Commit {
  committer: Committer;
  author: Author;
  message: string;
  sha: string;
}

interface Repository {
  path: string;
  scheme: string;
  name: string;
  host: string;
  id: string;
}

interface Git {
  commit: Commit;
  name: string;
  default_branch: string;
  repository_url: string;
  repository: Repository;
  branch: string;
}

interface Github {
  conclusion: string;
  html_url: string;
  run_attempt: string;
  event: string;
  app_id: string;
}

interface Pipeline {
  number: number;
  name: string;
  fingerprint: string;
  id: string;
  url: string;
}

interface Job {
  name: string;
  id: string;
  url: string;
}

interface Provider {
  instance: string;
  name: string;
}

interface Ci {
  pipeline: Pipeline;
  job: Job;
  provider: Provider;
  status: string;
}

export interface PipelineAttributes {
  duration: number;
  github: Github;
  git: Git;
  _top_level: number;
  ci: Ci;
  start: number;
  source: string;
  env?: string;
  team?: string;
  service?: string;
}

interface RootAttributes {
  attributes: PipelineAttributes;
  ci_level: string;
  tags: string[];
}

interface RootObject {
  id: string;
  type: string;
  attributes: RootAttributes;
  start: number;
}

// extend the RootObject
export type DatadogPipelineResult = RootObject;

export type DatadogDoraPayload = {
  data: DatadogPipelineResult[];
  links: {
    next: string;
  };
};

type DatadogSearchBody = {
  filter: {
    from: string;
    query: string;
    to: string;
  };
  options: {
    timezone: string;
  };
  page: {
    limit: number;
  };
  sort: string;
};
