import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';
import { Options } from '..';

const DEFAULT_PROXY_PATH = '/datadog/api';
const DEFAULT_REST_API_VERSION = 'v2';
const MAX_LIMIT_RESULT = 1000;

export const datadogSecApiRef = createApiRef<DatadogSecApi>({
  id: 'plugin.secops-compliance.datadog.service',
});

export class DatadogSecApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly identityApi: IdentityApi;
  private readonly fetchApi: FetchApi;

  constructor({ discoveryApi, identityApi, fetchApi }: Options) {
    this.discoveryApi = discoveryApi;
    this.identityApi = identityApi;
    this.fetchApi = fetchApi;
  }

  async getUrls() {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return {
      apiUrl: `${proxyUrl}${DEFAULT_PROXY_PATH}/api/${DEFAULT_REST_API_VERSION}`,
      datadogUrl: `https://api.datadoghq.eu/api/${DEFAULT_REST_API_VERSION}`,
    };
  }

  async getSecurityFindings(
    teamName?: string,
    framework?: string,
    severity?: string,
    cursor?: string | null,
  ): Promise<any> {
    const { token } = await this.identityApi.getCredentials();
    const { apiUrl } = await this.getUrls();
    const apiUrlPostureManagement = `${apiUrl}/posture_management/findings`;

    const teamFilter = teamName ? `team:${teamName}` : `team:*`;

    let requestUri = `${apiUrlPostureManagement}?filter[tags]=${teamFilter}&page[limit]=${MAX_LIMIT_RESULT}`;

    if (framework) {
      requestUri += `&filter[tags]=framework:${framework}`;
    }

    if (severity) {
      requestUri += `&filter[status]=${severity}`;
    }

    if (cursor) {
      requestUri += `&page[cursor]=${cursor}`;
    }

    const requestHeaders: Headers = {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };

    const data = await this.fetchApi.fetch(requestUri, requestHeaders);
    const response = await data.json();

    if (response.meta.page.cursor && cursor !== response.meta.page.cursor) {
      const newResult = await this.getSecurityFindings(
        teamName,
        framework,
        severity,
        response.meta.page.cursor,
      );
      return [...newResult, ...response.data];
    }

    return response.data;
  }
}

type Headers = {
  method: string;
  headers: {
    'Content-Type': string;
    Authorization: string;
    cursor?: string;
  };
};
