export const WEEK_IN_SECONDS = 24 * 60 * 60 * 7;

const escapeSpaces = (query: string) => {
  return query.replace(/\s+/g, ' ').trim();
};

const escapeCarrierReturn = (query: string) => {
  return query.replace(/(\r\n|\n|\r)/gm, '');
};

export const cleanQueryString = (query: string) => {
  const queryWithoutSpaces = escapeSpaces(query);
  return escapeCarrierReturn(queryWithoutSpaces);
};

/**
 * Calculate the timestamps for the period of time to fetch data from
 * @param period - number of weeks to fetch data from
 * @returns object with fromTimestamp and nowTimestamp
 */
export const calculateTimestamps = (period: number) => {
  const currentTime = new Date();
  const fromTime = new Date(currentTime);
  fromTime.setDate(fromTime.getDate() - period);

  const nowTimestamp = currentTime.getTime();
  const fromTimestamp = fromTime.getTime();
  return { fromTimestamp, nowTimestamp };
};

export const getWeekNumber = (date: Date) => {
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  const week1 = new Date(date.getFullYear(), 0, 4);
  return (
    1 +
    Math.round(
      ((date.getTime() - week1.getTime()) / 86400000 -
        3 +
        ((week1.getDay() + 6) % 7)) /
        7,
    )
  );
};

export const colorPalette = [
  '#003f5c',
  '#2f4b7c',
  '#665191',
  '#a05195',
  '#d45087',
  '#f95d6a',
  '#ff7c43',
  '#4925b4',
  '#9700a5',
  '#c9008f',
  '#ec0075',
  '#ff215a',
  '#ff563f',
  '#ff8024',
  '#6a60b4',
  '#9d5cb3',
  '#c856a8',
  '#eb5393',
  '#ff5a78',
  '#ff6d59',
  '#ff8837',
  '#ffa600',
  '#03cdd7',
  '#00d0c0',
  '#2dd1a1',
  '#5ecf7d',
  '#87cb58',
  '#afc331',
  '#d7b705',
  '#f3ff13',
  '#aaff58',
  '#53ff8e',
  '#00fabe',
  '#00efe5',
  '#00e2fe',
  '#00d2ff',
  '#5bc1ff',
  '#dd3d7f',
  '#f84563',
  '#ff5e40',
  '#ff7f04',
  '#f1a300',
  '#d0c400',
  '#9be300',
  '#18ff31',
  '#3fdd6c',
  '#90c73a',
  '#baaf1f',
  '#d6942a',
  '#e27a45',
  '#e06561',
  '#cf5a7c',
  '#b15990',
];
