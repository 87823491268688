import React, { useState, useEffect, useRef } from 'react';
import mermaid from 'mermaid';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

export const MermaidEditorToolbox = () => {
  const [input, setInput] = useState(`sequenceDiagram
    participant Bob
    participant Alice
    Bob->>Alice: Syn
    Alice-->>Bob: Syn/Ack
    Bob->>Alice: Ack`);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const diagramRef = useRef<HTMLDivElement>(null);

  const renderDiagram = async () => {
    try {
      const parsed = await mermaid.parse(input);

      if (parsed) {
        const { svg } = await mermaid.render('mermaidDiagram', input);
        if (diagramRef.current) {
          diagramRef.current.innerHTML = svg;
        }
        setErrorMessage(null);
      }
    } catch (error) {
      setErrorMessage("Couldn't render the diagram. Check your syntax.");
      if (diagramRef.current) {
        diagramRef.current.innerHTML = '';
      }
    }
  };

  useEffect(() => {
    mermaid.initialize({ startOnLoad: false });
    renderDiagram();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setErrorMessage(null);
  };

  return (
    <Box
      display="flex"
      padding="1em"
      sx={theme => ({
        fontFamily: theme.typography.fontFamily,
      })}
    >
      <Box flex={1} paddingRight="1em">
        <Typography variant="h6" gutterBottom>
          Mermaid Diagram Editor
        </Typography>

        <TextField
          multiline
          rows={20}
          value={input}
          onChange={handleInputChange}
          placeholder="Enter your sequence diagram text here..."
          variant="outlined"
          fullWidth
          sx={{
            fontFamily: 'monospace',
            marginBottom: '1em',
          }}
        />

        {errorMessage && (
          <Typography color="error" variant="body2" mt={2}>
            {errorMessage}
          </Typography>
        )}
      </Box>

      <Box flex={1} paddingLeft="1em" borderLeft="1px solid #ddd">
        <Typography variant="h6" gutterBottom>
          Rendered Diagram
        </Typography>

        <Paper
          ref={diagramRef}
          elevation={3}
          sx={theme => ({
            backgroundColor: theme.palette.background.default,
            padding: '1em',
            minHeight: '300px',
            borderRadius: 2,
          })}
        />
      </Box>
    </Box>
  );
};
