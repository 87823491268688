import { Entity } from '@backstage/catalog-model';
import { JIRA_PROJECT_KEY_ANNOTATION } from './hooks';
import { JiraClient } from './api/jira/Client';
import { DatadogDoraApi } from './api';

export { DoraMetricsCard } from './plugin';
export const isDoraProfileAvailable = (entity: Entity): boolean => {
  let enablePlugin: boolean = true;
  [JIRA_PROJECT_KEY_ANNOTATION].forEach(requiredAnnotation => {
    const annotationAtPath =
      entity?.metadata?.annotations?.[requiredAnnotation];
    if (!annotationAtPath) {
      enablePlugin = false;
    }
  });
  return enablePlugin;
};

export { JiraClient, DatadogDoraApi };
