import React from 'react';
import { InfoCard } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { useEntity } from '@backstage/plugin-catalog-react';
import Box from '@material-ui/core/Box';
import { useAsync } from 'react-use';
import { Grid, Typography } from '@material-ui/core';
import { costApiRef, CostMetricQuery } from '@internal/plugin-aws-cost';

const NUMBER_OF_MAX_DISPLAYED_ELEMENTS = 7;

const getIcon = (name: string) => {
  const baseCdnPath = 'https://cdn.prod.accelerator.sanofi/backstage/awsIcons/';
  const iconName = name === 's3' ? 's3bucket.svg' : `${name}.svg`;
  const defaultSrc = `${baseCdnPath}aws.png`;

  return (
    <img
      src={baseCdnPath + iconName}
      alt={name}
      onError={e => {
        (e.target as HTMLImageElement).src = defaultSrc;
      }}
    />
  );
};

const CostData = ({ value }: { value: AwsCostDataResults }) => {
  const structDataSeries = (
    dataSeries: AwsCostDataSeries[],
  ): CostDataInfo[] => {
    return dataSeries.map((serie: AwsCostDataSeries, index: number) => {
      const [line] = serie.group_tags;
      const accumulateCostForLine = value.data.attributes.values[index].reduce(
        (acc: number, val: number) => acc + val,
        0,
      );
      const resourceName = line.substring('aws_product:'.length);
      const displayName =
        resourceName === 'secretsmanager' ? 'Sec. Manag' : resourceName;

      return {
        key: line,
        resourceName,
        displayName,
        amount: +accumulateCostForLine.toFixed(2),
      };
    });
  };

  const orderedSeries = (costDataSeries: CostDataInfo[]) => {
    return costDataSeries.sort((a, b) => {
      if (a.amount < b.amount) {
        return 1;
      } else if (a.amount > b.amount) {
        return -1;
      }
      return 0;
    });
  };

  const structuredData = structDataSeries(value.data.attributes.series);
  const orderedData = orderedSeries(structuredData);
  const getElementsFilteredList = () => {
    return orderedData
      .map((data: CostDataInfo) => (
        <Grid item xs={12} key={data.key}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginX: '5px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'nowrap',
                alignItems: 'center',
                justifyContent: 'space-between',
                marginX: '5px',
              }}
            >
              {getIcon(data.resourceName)}
              &nbsp;
              <Typography variant="caption">{data.displayName} </Typography>
            </Box>
            <Typography variant="caption">{`$${data.amount}`}</Typography>
          </Box>
        </Grid>
      ))
      .slice(0, NUMBER_OF_MAX_DISPLAYED_ELEMENTS);
  };

  return (
    <>
      {getElementsFilteredList()}
      {orderedData.length > NUMBER_OF_MAX_DISPLAYED_ELEMENTS && (
        <Grid item xs={12} key="h3">
          <Box>
            <Typography variant="subtitle2" align="left">
              + {orderedData.length - NUMBER_OF_MAX_DISPLAYED_ELEMENTS} more
            </Typography>
          </Box>
        </Grid>
      )}
    </>
  );
};

export const AwsCostOverviewCard = () => {
  const { entity } = useEntity();
  const costApi = useApi(costApiRef);
  const periodInDays = 7;

  const weekBefore = new Date();
  // set time to 00:00:00
  weekBefore.setHours(2, 0, 0, 0);
  // We want 7 data points, so we need to go back 8 days
  weekBefore.setDate(weekBefore.getDate() - (periodInDays + 1));
  const settings: CostMetricQuery = {
    fromTimestamp: weekBefore.getTime(),
    nowTimestamp: new Date().getTime(),
    filters: [
      { key: 'env', value: '*' },
      { key: 'team', value: entity.metadata.name },
    ],
    rollUpTimeInSeconds: 86400,
  };

  const { loading, value, error } = useAsync(() =>
    costApi.getCostMgmtMetrics(settings),
  );

  if (loading) return <InfoCard title="AWS Cost" subheader="Loading" />;

  if (error || !value?.data?.attributes?.values) {
    return (
      <InfoCard
        title="AWS Cost"
        subheader="Unable to fetch data from the API. Please check the configuration."
      />
    );
  }

  const globalTotal = value.data.attributes.values.reduce(
    (globalAccumulator: number, product: any) => {
      return (
        globalAccumulator +
        product.reduce(
          (innerAcc: number, innerVal: number) =>
            innerVal ? innerAcc + innerVal : innerAcc,
          0,
        )
      );
    },
    0,
  );

  return (
    <InfoCard
      title="AWS Cost"
      subheader={`Total: $${globalTotal.toFixed(
        2,
      )} in the last ${periodInDays} days`}
      deepLink={{
        title: 'View full cost data',
        link: `/catalog/default/Group/${entity.metadata.name}/aws-cost`,
      }}
    >
      <Grid
        container
        style={{
          minHeight: 280,
        }}
      >
        <CostData value={value} />
      </Grid>
    </InfoCard>
  );
};

type AwsCostDataResults = {
  data: {
    attributes: {
      series: AwsCostDataSeries[];
      values: number[][];
    };
  };
};

type AwsCostDataSeries = {
  group_tags: string[];
  query_index: number;
  unit: [
    {
      family: string;
      name: string;
      plural: string;
    },
  ];
};

type CostDataInfo = {
  key: string;
  resourceName: string;
  displayName: string;
  amount: number;
};
