import { DatadogSecApi, datadogSecApiRef } from './datadog';
import {
  createApiRef,
  DiscoveryApi,
  FetchApi,
  IdentityApi,
} from '@backstage/core-plugin-api';

export type Options = {
  discoveryApi: DiscoveryApi;
  identityApi: IdentityApi;
  fetchApi: FetchApi;
};

export const secApiRef = createApiRef<SecOpsFindings>({
  id: 'plugin.secops-compliance.datadog.service',
});

class SecOpsFindings {
  private readonly datadog: DatadogSecApi;

  constructor(options: Options) {
    this.datadog = new DatadogSecApi(options);
  }

  async getSecFindings({ teamName, framework, severity }: any): Promise<any> {
    const secFindings = await this.datadog.getSecurityFindings(
      teamName,
      framework,
      severity,
    );
    return {
      data: secFindings,
    };
  }
}
export { datadogSecApiRef, DatadogSecApi, SecOpsFindings };
