import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import {
  HomePageStarredEntities,
  HomePageToolkit,
  RendererProps,
} from '@backstage/plugin-home';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SearchContextProvider } from '@backstage/plugin-search-react';
import { Content, InfoCard, Link, Page } from '@backstage/core-components';
import { HomePageSearchBar } from '@backstage/plugin-search';
import Pets from '@material-ui/icons/Pets';
import Security from '@material-ui/icons/Security';
import GitHub from '@material-ui/icons/GitHub';
import Assignment from '@material-ui/icons/Assignment';
import Apps from '@material-ui/icons/Apps';
import Cloud from '@material-ui/icons/Cloud';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Code from '@material-ui/icons/Code';
import Help from '@material-ui/icons/Help';
import Mood from '@material-ui/icons/Mood';
import Receipt from '@material-ui/icons/Receipt';
import School from '@material-ui/icons/School';
import ZoomIn from '@material-ui/icons/ZoomIn';
import WbSunny from '@material-ui/icons/WbSunny';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import FormatPaint from '@material-ui/icons/FormatPaint';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import { HomeNavBarHeader } from './HomeNavBarHeader';
import { HomeMyReposCard } from './HomeMyReposCard';
import {
  BackstageUserIdentity,
  identityApiRef,
  ProfileInfo,
  useApi,
} from '@backstage/core-plugin-api';
import {
  catalogApiRef,
  useStarredEntities,
} from '@backstage/plugin-catalog-react';
import { Entity, RELATION_OWNED_BY } from '@backstage/catalog-model';
import { GetEntitiesResponse } from '@backstage/catalog-client';
import OfflineBoltOutlinedIcon from '@material-ui/icons/OfflineBoltOutlined';

const USEFUL_LINKS_TECH_DATA = [
  {
    url: 'https://github.com/Sanofi-Accelerator/engineering-docs/tree/main',
    label: 'Engineering Docs',
    icon: <FlightTakeoffIcon color="secondary" />,
  },
  {
    url: 'https://elements.sanofidesign.com/557b0250b/p/71eae0-elements-design-system',
    label: 'Design System',
    icon: <FormatPaint color="secondary" />,
  },
  {
    url: 'https://github.com/Sanofi-Accelerator/',
    label: 'Github',
    icon: <GitHub color="secondary" />,
  },
  {
    url: 'https://sonarcloud.io/organizations/sanofi-accelerator/projects',
    label: 'Sonar Cloud',
    icon: <Security color="secondary" />,
  },
  {
    url: 'https://aws.amazon.com/fr/',
    label: 'AWS',
    icon: <Cloud color="secondary" />,
  },
  {
    url: 'https://app.datadoghq.eu/apm/home',
    label: 'Datadog',
    icon: <Pets color="secondary" />,
  },
  {
    url: 'https://app.launchdarkly.com/',
    label: 'Launch Darkly',
    icon: <PlayArrow color="secondary" />,
  },
  {
    url: 'https://sanofi-2.postman.co/',
    label: 'Postman',
    icon: <Code color="secondary" />,
  },
  {
    url: 'https://backstage.prod.accelerator.sanofi/catalog/default/system/platform-backstage/docs',
    label: 'Backstage docs',
    icon: <MenuBookIcon color="secondary" />,
  },
  {
    url: 'https://sanofi.atlassian.net/',
    label: 'Jira',
    icon: <Assignment color="secondary" />,
  },
];

const USEFUL_LINKS_OTHER_DATA = [
  {
    url: 'https://github.com/Sanofi-Accelerator/engineering-docs',
    label: 'Docs',
    icon: <Receipt color="secondary" />,
  },
  {
    url: 'https://myapplications.microsoft.com/',
    label: 'MyApps',
    icon: <Apps color="secondary" />,
  },
  {
    url: 'https://sanofiservices.service-now.com/onesupport',
    label: 'Service Now',
    icon: <Help color="secondary" />,
  },
  {
    url: 'https://sanofi.sharepoint.com/sites/buzz#/home',
    label: 'Buzz',
    icon: <WbSunny color="secondary" />,
  },
  {
    url: 'https://sanofi-learning.csod.com/',
    label: 'iLearn',
    icon: <School color="secondary" />,
  },
  {
    url: 'https://sanofi.udemy.com/',
    label: 'Udemy',
    icon: <School color="secondary" />,
  },
  {
    url: 'https://www.csesag.fr/',
    label: 'CSE',
    icon: <Mood color="secondary" />,
  },
  {
    url: 'https://backstage.io/docs/overview/what-is-backstage',
    label: 'Backstage',
    icon: <ZoomIn color="secondary" />,
  },
];

const useStyles = makeStyles<Theme>(theme => {
  const snowflakesStyles: Record<string, React.CSSProperties> = {};
  const totalSnowflakes = 10; // Adjust the number of snowflakes as needed
  for (let i = 0; i < totalSnowflakes; i++) {
    const leftPosition = i * (100 / totalSnowflakes);
    const animationDelay = i % 3 === 0 ? i / 3 : i;
    snowflakesStyles[`&:nth-child(${i + 1})`] = {
      left: `${leftPosition}%`,
      animationDelay: `${animationDelay}s, ${animationDelay / 2}s`,
    };
  }
  return {
    title: {
      marginBottom: '2rem',
    },
    searchBarInput: {
      maxWidth: '60vw',
      margin: 'auto',
      backgroundColor: theme.palette.background.paper,
      borderRadius: '50px',
      boxShadow: theme.shadows[1],
    },
    searchBarOutline: {
      borderStyle: 'true',
    },
    snowContainer: {
      position: 'fixed',
      top: 0,
      left: 0,
      pointerEvents: 'none',
      zIndex: 9999,
    },
    snowflake: {
      position: 'absolute',
      fontFamily: 'Arial, sans-serif',
      color: '#fff',
      userSelect: 'none',
      pointerEvents: 'none',
      opacity: 0.8,
      animation: 'snowfall linear infinite, shake ease-in-out infinite',
    },
    ...snowflakesStyles,
  };
});

export const HomePage = () => {
  const classes = useStyles();
  const [profileInfo, setProfileInfo] = useState<ProfileInfo | undefined>();
  const [profileIdentity, setProfileIdentity] = useState<
    BackstageUserIdentity | undefined
  >();
  const [userEntitiesRepos, setUserEntitiesRepos] = useState<
    GetEntitiesResponse | undefined
  >();

  const [templates, setTemplates] = useState<GetEntitiesResponse | undefined>();
  const [joke, setJoke] = useState<Joke | undefined>();
  const identityApi = useApi(identityApiRef);
  const catalogApi = useApi(catalogApiRef);

  const { isStarredEntity } = useStarredEntities();

  const getNewJoke = async (): Promise<Joke> => {
    try {
      const response = await fetch(
        `https://official-joke-api.appspot.com/jokes/programming/random`,
      );
      const data = await response.json();
      return Array.isArray(data) ? data[0] : data;
    } catch (error) {
      throw new Error(`'can not fetch joke: '${error}`);
    }
  };

  const sortAndSetStarredEntity = (result: GetEntitiesResponse) => {
    result.items.sort((a: Entity, b: Entity) => {
      const isAStarred = isStarredEntity(a);
      const isBStarred = isStarredEntity(b);
      if (isAStarred && !isBStarred) {
        return -1;
      } else if (!isAStarred && isBStarred) {
        return 1;
      }
      return 0;
    });
    setUserEntitiesRepos(result);
  };

  const fetchUserEntities = async (kindEntity: string, relation: string) => {
    if (!profileIdentity) {
      throw new Error('Profile identity is missing');
    }
    try {
      return await catalogApi.getEntities({
        filter: {
          kind: [kindEntity],
          [`relations.${relation}`]: profileIdentity.ownershipEntityRefs,
        },
      });
    } catch (error) {
      throw new Error(`'can not fetch user related entities '${error}`);
    }
  };

  const fetchTemplates = async () => {
    try {
      return await catalogApi.getEntities({
        filter: {
          kind: 'Template',
          [`metadata.tags`]: 'onboarding',
        },
      });
    } catch (error) {
      throw new Error(`'can not fetch templates entities '${error}`);
    }
  };

  const getAllEntitiesByUser = async (
    kindEntity: string,
    relation: string,
  ): Promise<any> => {
    let result = null;
    if (profileIdentity) {
      try {
        result = await fetchUserEntities(kindEntity, relation);
        result.items = result.items
          .filter((item: Entity) => {
            const ownerRelations = item.relations?.filter(
              r =>
                r.type === 'ownedBy' &&
                r.targetRef !== 'group:default/accelerator',
            );
            return ownerRelations && ownerRelations.length > 0;
          })
          .slice(0, 8);
        sortAndSetStarredEntity(result);
      } catch (error) {
        throw new Error(`'can not process user related entities '${error}`);
      }
    }
    return result;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!profileIdentity) {
          const [profileId, randJoke] = await Promise.all([
            identityApi.getBackstageIdentity(),
            getNewJoke(),
          ]);
          setProfileIdentity(profileId);
          setJoke(randJoke);
        }
        const [profileData] = await Promise.all([
          identityApi.getProfileInfo(),
          getAllEntitiesByUser('component', RELATION_OWNED_BY),
        ]);
        const templatesAvailable = await fetchTemplates();
        setTemplates(templatesAvailable);
        setProfileInfo(profileData);
      } catch (error) {
        throw Error('error getting entities by user');
      }
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileIdentity]);

  const renderStarredEntities = (render: RendererProps) => {
    // eslint-disable-next-line new-cap
    const content = render.Content({
      noStarredEntitiesMessage:
        'Start by adding a star to a repository to find your favorites here',
    });

    const generateStarredList = () => {
      if (!Array.isArray(content.props.children)) {
        return content;
      }
      return content.props.children[0].props.children.map(
        (starredRepo: JSX.Element) => (
          <Grid item key={starredRepo.key}>
            {starredRepo}
          </Grid>
        ),
      );
    };

    return (
      <InfoCard title="Starred Entities">
        <Grid container direction="row">
          {generateStarredList()}
        </Grid>
      </InfoCard>
    );
  };

  return (
    <SearchContextProvider>
      <Page themeId="home">
        <HomeNavBarHeader jokeProps={joke} profileProps={profileInfo} />
        <Content>
          <Grid container justifyContent="center">
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'block',
                },
              }}
            >
              <img
                src="https://cdn.prod.accelerator.sanofi/backstage/heroImage/classicScience.webp"
                alt="logo"
                style={{
                  borderRadius: '20px',
                  marginTop: '20px',
                  marginBottom: '20px',
                  width: '60vw',
                }}
              />
            </Box>
            <Grid
              container
              item
              xs={12}
              justifyContent="center"
              style={{ marginBottom: '20px' }}
            >
              <HomePageSearchBar
                InputProps={{
                  classes: {
                    root: classes.searchBarInput,
                    notchedOutline: classes.searchBarOutline,
                  },
                }}
                placeholder="Search in Backstage Accelerator"
              />
            </Grid>
            <Grid container item direction="row">
              <Grid item xs={12} md={8}>
                <Grid item xs={12}>
                  <HomePageStarredEntities Renderer={renderStarredEntities} />
                </Grid>
                &nbsp;
                <Grid item xs={12}>
                  <HomeMyReposCard userEntitiesReposProps={userEntitiesRepos} />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <HomePageToolkit
                  title="Tech links"
                  tools={USEFUL_LINKS_TECH_DATA}
                />
                &nbsp;
                <HomePageToolkit
                  title="Other links"
                  tools={USEFUL_LINKS_OTHER_DATA}
                />
                &nbsp;
                <Grid item xs={12}>
                  <InfoCard
                    title="Workflows"
                    deepLink={{
                      title: 'View all workflows',
                      link: '/scaffolder?filters[kind]=template&filters[user]=all',
                    }}
                  >
                    <List component="nav">
                      <Link to="/catalog-import">
                        <ListItem button>
                          <ListItemIcon>
                            <OfflineBoltOutlinedIcon />
                          </ListItemIcon>
                          <ListItemText primary="Enroll your repository" />
                        </ListItem>
                      </Link>
                      {templates?.items.map(item => (
                        <Link
                          to={`/scaffolder/templates/default/${item.metadata.name}`}
                          key={item.metadata.etag}
                        >
                          <ListItem button key={item.metadata.etag}>
                            <ListItemIcon>
                              <OfflineBoltOutlinedIcon />
                            </ListItemIcon>
                            <ListItemText primary={item.metadata.title} />
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </InfoCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Content>
      </Page>
    </SearchContextProvider>
  );
};

export type Joke = {
  setup: string;
  punchline: string;
};
